import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import SiteImage from "../components/SiteImage"
import HeroSlides from "../components/HeroSlides"
import SectionTitle from "../components/SectionTitle"
import { Autoplay, EffectCards, Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import Moment from "react-moment"
import "./tournaments.scss"
import "../fragments/image"
import VideoSection from "../components/VideoSection"
import ContinousSlider from "../components/ContinousSlider"
import FaqSection from "../components/FaqSection"

const MadalaSwiper = ({ children, showCards, onTap }) =>
  showCards ? (
    <Swiper
      key={0}
      modules={[EffectCards, Pagination, Navigation]}
      slidesPerView={2}
      centeredSlides
      pagination={{
        clickable: true,
      }}
      navigation
      effect="cards"
      loop
      cardsEffect={{
        rotate: false,
        perSlideOffset: 70,
      }}
      autoplay={{
        pauseOnMouseEnter: true,
      }}
      onTap={onTap}
    >
      {children}
    </Swiper>
  ) : (
    <Swiper
      key={1}
      modules={[Autoplay, Pagination]}
      pagination={{
        clickable: true,
      }}
      loop
      spaceBetween={50}
    >
      {children}
    </Swiper>
  )

function MadalaSection({
  announceText,
  title,
  description,
  TournamentDetailBaseUrl,
  TournamentResultsBaseUrl,
  TournamentItems,
}) {
  const [showCards, setShowCards] = useState(false)

  const flipNegative = v => (v[0] === "-" ? v.slice(1) : `-${v}`)

  useEffect(() => {
    const onResize = () => {
      setShowCards(document.body.offsetWidth > 768)
    }
    onResize()
    window.addEventListener("resize", onResize)
    return () => window.removeEventListener("resize", onResize)
  }, [])

  return (
    <section id="madala-section" className="section">
      <div className="container">
        <SectionTitle
          announceText={announceText}
          title={title}
          description={description}
        />
        <MadalaSwiper showCards={showCards}>
          {TournamentItems.map(
            ({ id, image, title, startTime, endTime, tournamentId }) => {
              const isEnded = new Date(endTime).getTime() < Date.now()
              const isStarted = new Date(startTime).getTime() <= Date.now()
              const link = `${
                isEnded ? TournamentResultsBaseUrl : TournamentDetailBaseUrl
              }${tournamentId}`
              return (
                <SwiperSlide key={id}>
                  <a
                    href={link}
                    rel="noopener noreferrer"
                    className="main-container has-text-white m-2"
                    target="_blank"
                  >
                    <div className="content-container">
                      <div className="image-container">
                        <SiteImage className="madala-image" image={image} alt="" />
                      </div>
                      <div className="is-flex is-flex-direction-column">
                        <div className="m-5 has-text-white title is-5">
                          {title}
                        </div>
                      </div>
                      <div className="mx-5">
                        <div className="columns is-multiline is-vcentered">
                          <div className="column is-narrow-widescreen is-full-desktop is-full-tablet is-full-mobile is-narrow-fullhd">
                            <div className="is-flex is-align-items-flex-end">
                              <div>
                                {isEnded
                                  ? "Ended"
                                  : isStarted
                                  ? "Ends in"
                                  : "Starts in"}
                              </div>
                              {isEnded ? null : (
                                <div className="ml-3">
                                  <Moment
                                    interval={1000}
                                    date={
                                      new Date(isStarted ? endTime : startTime)
                                    }
                                    format={
                                      Math.abs(
                                        new Date(
                                          isStarted ? endTime : startTime
                                        ).getTime() - Date.now()
                                      ) >
                                      60 * 60 * 1000
                                        ? "H:mm:ss"
                                        : "mm:ss"
                                    }
                                    durationFromNow
                                    filter={flipNegative}
                                    element={({ children }) => (
                                      <div className="timer-info-row">
                                        {children.split("").map((t, i) => (
                                          <React.Fragment key={i}>
                                            {isNaN(t) ? (
                                              <div className="time-left-text">
                                                {t}
                                              </div>
                                            ) : (
                                              <div className="gradient-box-container">
                                                <div className="gradient-box time-elapsed-number">
                                                  {t}
                                                </div>
                                              </div>
                                            )}
                                          </React.Fragment>
                                        ))}
                                      </div>
                                    )}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="column is-narrow">
                            <div className="is-flex is-align-items-flex-end">
                              <div className="mr-2">
                                <Moment
                                  date={
                                    new Date(isStarted ? endTime : startTime)
                                  }
                                  format={"L"}
                                />
                              </div>
                              <div>
                                <Moment
                                  date={
                                    new Date(isStarted ? endTime : startTime)
                                  }
                                  format={"LT"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="m-5 button is-gradient is-family-secondary is-rounded py-0">
                        {isEnded ? "See Results" : "Join Now"}
                      </div>
                    </div>
                  </a>
                </SwiperSlide>
              )
            }
          )}
        </MadalaSwiper>
      </div>
    </section>
  )
}

function ExploreSection({ announceText, title, description, TournamentTypes }) {
  const renderFrame = () => (
    <svg
      viewBox="0 0 294 119"
      fill="none"
      className="tournament-type-background"
    >
      <path
        d="M1.05 1.716h113.377v.242c0 2.628 2.983 4.773 6.638 4.773h51.87c3.655 0 6.638-2.145 6.638-4.773v-.242H292.95v116.437H179.503c-.504-2.276-3.249-4.018-6.554-4.018h-51.87c-3.305 0-6.05 1.742-6.554 4.018H1.05V83.855h.35c3.655 0 4.286-2.144 4.286-4.773V41.784c0-2.628-.63-4.773-4.286-4.773h-.35V1.696M0 .941v36.956c.448-.08.91-.14 1.4-.14 3.081 0 3.236 1.802 3.236 4.017v37.298c0 2.216-.155 4.018-3.236 4.018-.49 0-.952-.06-1.4-.14v35.958h115.477c0-2.215 2.507-4.018 5.588-4.018h51.87c3.081 0 5.588 1.803 5.588 4.018H294V.951H178.327c.112.322.196.655.196.997 0 2.215-2.507 4.018-5.588 4.018v.765h-51.87c-3.081 0-5.588-2.568-5.588-4.783 0-.352.084-.675.196-.997H0v-.01z"
        fill="url(#prefix__paint0_linear_9308_151560)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_9308_151560"
          x1={147}
          y1={118.908}
          x2={147}
          y2={0.941}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3772FF" />
          <stop offset={1} stopColor="#E031CA" />
        </linearGradient>
      </defs>
    </svg>
  )
  return (
    <section id="explore-section" className="section">
      <div className="container">
        <SectionTitle
          announceText={announceText}
          title={title}
          description={description}
        />
        <div className="columns is-multiline">
          {TournamentTypes.map(({ title, description, id, icon }) => {
            return (
              <div
                className="column is-half is-flex is-flex-direction-column is-align-items-center"
                key={id}
              >
                <div className="tournament-type-container">
                  {renderFrame()}
                  <SiteImage
                    className="tournament-type-icon"
                    width={"100px"}
                    height={"100px"}
                    alt=""
                    image={icon}
                  />
                  <div className="tournament-type-title">
                    <div>{title}</div>
                  </div>
                  <div>{description}</div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

function HighlightsSection({
  announceText,
  title,
  description,
  slides,
  joinLink,
}) {
  return (
    <section id="highlight-section" className="section">
      <div className="container">
        <SectionTitle
          announceText={announceText}
          title={title}
          description={description}
        />
        <ContinousSlider>
          {slides.map(image => (
            <SwiperSlide key={image.id} style={{ width: "auto" }}>
              <div>
                <SiteImage className="highlight-image" alt="" image={image} />
              </div>
            </SwiperSlide>
          ))}
        </ContinousSlider>
        <div className="is-flex is-justify-content-center">
          <a
            href={joinLink.link}
            target="_blank"
            rel="noreferrer"
            className="m-5 button is-gradient is-family-secondary has-text-white is-rounded"
          >
            {joinLink.image && (
              <SiteImage
                className="mx-3"
                width={16}
                height={16}
                alt=""
                image={joinLink.image}
              />
            )}
            {joinLink.title}
          </a>
        </div>
      </div>
    </section>
  )
}

export default function Tournaments({
  data: {
    strapiTournament: {
      seo,
      hero: heroSlides,
      Madala,
      Explore,
      Highlights,
      Video,
      Faq,
    },
  },
}) {
  return (
    <Layout showNewsletterCTA>
      <Seo
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        jsonLDSchema={seo.jsonLDSchema}
        pathname={"tournaments"}
      />
      <HeroSlides
        heroSlides={heroSlides}
        thumbnailNames={["Overview", "Madala Challenges", "Easy Tournaments"]}
        thumbnailImages={heroSlides.map(slide => slide.heroImage)}
      />
      <MadalaSection
        title={Madala.title}
        announceText={Madala.announceText}
        description={Madala.description}
        TournamentDetailBaseUrl={Madala.TournamentDetailBaseUrl}
        TournamentResultsBaseUrl={Madala.TournamentResultsBaseUrl}
        TournamentItems={Madala.TournamentItems}
      />
      <VideoSection
        announceText={Video.announceText}
        title={Video.title}
        description={Video.description}
        videoLinks={Video.videoLinks}
        buttonLink={Video.buttonLink}
      />
      <ExploreSection
        title={Explore.title}
        announceText={Explore.announceText}
        description={Explore.description}
        TournamentTypes={Explore.TournamentType}
        TournamentResultsBaseUrl={Explore.TournamentResultsBaseUrl}
        TournamentItems={Explore.TournamentItems}
      />
      <HighlightsSection
        title={Highlights.title}
        announceText={Highlights.announceText}
        description={Highlights.description}
        slides={Highlights.slides}
        joinLink={Highlights.joinLink}
      />
      <FaqSection
        announceText={Faq.announceText}
        title={Faq.title}
        faqItems={Faq.faqItem}
      />
    </Layout>
  )
}

export const query = graphql`
  {
    strapiHome {
      appLinks {
        title
        description
        background {
          ...StrapiImage
        }
        storeLinks {
          id
          title
          link
          image {
            ...StrapiImage
          }
        }
      }
    }
    strapiTournament {
      seo {
        title
        description
        keywords
        jsonLDSchema
      }
      hero {
        id
        title
        description
        link
        linkTitle
        heroImage {
          ...StrapiImage
        }
        background {
          ...StrapiImage
        }
      }
      Madala {
        announceText
        title
        description
        TournamentDetailBaseUrl
        TournamentResultsBaseUrl
        TournamentItems {
          id
          title
          image {
            ...StrapiImage
          }
          startTime
          endTime
          tournamentId
          videoLink
        }
      }
      Explore {
        announceText
        title
        description
        TournamentType {
          id
          title
          description
          icon {
            ...StrapiImage
          }
        }
      }
      Highlights {
        announceText
        title
        description
        slides {
          ...StrapiImage
        }
        joinLink {
          title
          link
          image {
            ...StrapiImage
          }
        }
      }
      Video {
        announceText
        title
        description
        videoLinks {
          id
          link
        }
        buttonLink {
          title
          link
        }
      }
      Faq {
        announceText
        title
        faqItem {
          id
          question
          answer {
            data {
              answer
            }
          }
        }
      }
    }
  }
`
