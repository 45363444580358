import React from 'react';

export default function FormattedText({ children }) {
  if (!children) return null;
  if (typeof children !== 'string') return children;

  // First replace &nbsp; with actual non-breaking space character
  const textWithNbsp = children.replace(/&nbsp;/g, '\u00A0');

  // Then split by newlines and join with br tags
  const parts = textWithNbsp.split('\n');

  return (
    <>
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {index < parts.length - 1 && <br />}
        </React.Fragment>
      ))}
    </>
  );
}
