import React from "react"
import { graphql } from "gatsby"
import { useForm } from "react-hook-form"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import SiteImage from "../components/SiteImage"
import "./contact.scss"
import "../fragments/image"

export default function Contact({
  data: {
    strapiContact: {
      title,
      seo,
      namePlaceholder,
      emailPlaceholder,
      phonePlaceholder,
      subjectPlaceholder,
      commentPlaceholder,
      sendButtonText,
      sendButtonSuccessText,
      sendButtonErrorText,
      moreInfoText,
      emailRequiredText,
      emailValidText,
      commentRequiredText,
    },
    strapiNewsletterCta: { socialLinks },
  },
}) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitSuccessful, isSubmitting, isSubmitted },
  } = useForm()

  const onSubmit = async data => {
    try {
      const apiURL = process.env.STRAPI_API_URL || `http://localhost:1337`
      const res = await fetch(`${apiURL}/api/messages`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data,
        }),
      })
      if (!res.ok) {
        throw new Error()
      }
      const body = await res.json()
      if (body.error?.message) {
        throw new Error()
      }
    } catch (err) {
      setError("server", new Error())
    }
  }

  return (
    <Layout>
      <Seo
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        jsonLDSchema={seo.jsonLDSchema}
        pathname={"contact"}
      />
      <section className="section is-medium" id="contact">
        <div className="container">
          <h1 className="title is-1">{title}</h1>
          <form className="tile is-ancestor" onSubmit={handleSubmit(onSubmit)}>
            <div className="tile is-vertical is-7">
              <div className="tile">
                <div className="tile is-parent is-7">
                  <input
                    className="tile is-child input is-rounded"
                    placeholder={namePlaceholder}
                    {...register("name")}
                  />
                  {errors.name && <div>FIX ME</div>}
                </div>
                <div className="tile is-parent">
                  <div className="tile is-child">
                    <input
                      className={`input is-rounded ${
                        errors.email ? "is-danger" : ""
                      }`}
                      placeholder={emailPlaceholder}
                      {...register("email", {
                        required: true,
                        pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                      })}
                    />
                    {errors.email && (
                      <div className="has-text-danger mt-2">
                        {
                          {
                            required: emailRequiredText,
                            pattern: emailValidText,
                          }[errors.email.type]
                        }
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="tile is-vertical">
                <div className="tile is-parent">
                  <input
                    className="tile is-child input is-rounded"
                    placeholder={phonePlaceholder}
                    {...register("phone")}
                  />
                </div>
                <div className="tile is-parent">
                  <input
                    className="tile is-child input is-rounded"
                    placeholder={subjectPlaceholder}
                    {...register("subject")}
                  />
                </div>
                <div className="tile is-parent">
                  <div className="tile is-child">
                    <textarea
                      className={`tile is-child textarea has-fixed-size ${
                        errors.content ? "is-danger" : ""
                      }`}
                      placeholder={commentPlaceholder}
                      rows="3"
                      {...register("content", { required: true })}
                    ></textarea>
                    {errors.content && (
                      <div className="has-text-danger mt-2">
                        {commentRequiredText}
                      </div>
                    )}
                  </div>
                </div>
                <div className="tile is-parent">
                  <button
                    type="submit"
                    disabled={isSubmitSuccessful || isSubmitting}
                    className={`tile is-child button  is-rounded  ${
                      isSubmitting ? "is-loading" : ""
                    } ${errors.server ? "is-danger" : "is-gradient"}`}
                  >
                    {errors.server
                      ? sendButtonErrorText
                      : isSubmitted && isSubmitSuccessful
                      ? sendButtonSuccessText
                      : sendButtonText}
                  </button>
                </div>
              </div>
            </div>
            <div className="tile is-parent">
              <div className="tile is-child">
                <div className="more-info">
                  <h3 className="title is-3">{moreInfoText}</h3>
                  <div className="is-flex">
                    {socialLinks.map(link => (
                      <a
                        key={link.id}
                        href={link.link}
                        className="mr-5 my-5"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <SiteImage image={link.image} alt={link.title} />
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    strapiContact {
      seo {
        title
        description
        keywords
        jsonLDSchema
      }
      title
      namePlaceholder
      emailPlaceholder
      phonePlaceholder
      subjectPlaceholder
      commentPlaceholder
      sendButtonText
      sendButtonSuccessText
      sendButtonErrorText
      moreInfoText
      emailRequiredText
      emailValidText
      commentRequiredText
    }
    strapiNewsletterCta {
      socialLinks {
        id
        title
        link
        image {
          ...StrapiImage
        }
      }
    }
  }
`
