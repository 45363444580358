import React from "react"
import "./SectionTitle.scss"
import FormattedText from "./FormattedText"

export default function SectionTitle({ announceText, title, description, leftAligned }) {
  return (
    <div className={`section-title-with-announce-container ${leftAligned ? 'left-aligned' : ''}`}>
      <div className="announce-text-container">
        <div className="announce-text">{announceText}</div>
      </div>
      <h2 className="title-container">
        <div className="title is-2">
          <FormattedText>{title}</FormattedText>
        </div>
      </h2>
      <p className="description">{description}</p>
    </div>
  )
}