import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Pagination } from "swiper"
import "./ContinousSlider.scss"

export default function ContinousSlider({ children, speed = 15000 }) {
  return (
    <Swiper
      key={1}
      slidesPerView={"auto"}
      spaceBetween={40}
      effect={"slide"}
      loop
      freeMode
      speed={speed}
      autoplay={{
        delay: 1,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
        waitForTransition: true,
        stopOnLastSlide: false,
      }}
      modules={[Autoplay, Pagination]}
    >
      {children}
    </Swiper>
  )
}