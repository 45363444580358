import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import SiteImage from "../components/SiteImage"
import HeroSlides from "../components/HeroSlides"
import VideoSection from "../components/VideoSection"
import ContinousSlider from "../components/ContinousSlider"
import FaqSection from "../components/FaqSection"
import { SwiperSlide } from "swiper/react"
import SectionTitle from "../components/SectionTitle"
import SmartLink from "../components/SmartLink"
import "../styles/scss/index.scss"
import "./index.scss"
import "../fragments/image"

export default function Home({
  data: {
    strapiHome: {
      seo,
      hero,
      featureList,
      propositionList,
      tournaments,
      appLinks,
      clubContact,
      video,
      gameScreenshots,
      faq,
    },
  },
}) {
  // Transform hero data to match HeroSlides component structure
  const heroSlides =
    hero?.map(slide => ({
      id: slide.id,
      title: slide.subtitle || slide.title,
      description: slide.description,
      link: slide.link,
      linkTitle: slide.linkTitle,
      secondaryLink: slide.secondaryLink,
      secondaryLinkTitle: slide.secondaryLinkTitle,
      heroImage: slide.heroImage,
      background: slide.background,
      thumbnailLabel: slide.thumbnailLabel,
      thumbnailImage: slide.thumbnailImage,
    })) || []

  return (
    <Layout showNewsletterCTA>
      <Seo
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        jsonLDSchema={seo.jsonLDSchema}
        pathname={"/"}
      />

      <HeroSlides
        heroSlides={heroSlides}
        thumbnailNames={heroSlides.map(slide => slide.thumbnailLabel)}
        thumbnailImages={heroSlides.map(
          slide => slide.thumbnailImage || slide.heroImage
        )}
      />
      <section id="proposition-list" className="section">
        <div className="container py-6">
          <SectionTitle title={propositionList.title} announceText="FEATURES" />
          <div className="columns is-multiline is-centered">
            {propositionList.proposition.map(proposition => (
              <div
                className="column is-narrow px-5 py-5 is-flex is-justify-content-center"
                key={proposition.id}
              >
                <div className="proposition-item">
                  <SiteImage
                    className="proposition-image"
                    image={proposition.logo}
                    alt=""
                  />
                  <div className="proposition-content">
                    <h4 className="title is-4 proposition-title has-text-silver-gradient">
                      {proposition.title}
                    </h4>
                    <p className="is-flex-grow-1">{proposition.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="tournaments" className="section">
        <div className="container background-container pt-6">
          <SiteImage
            className="tournaments-background"
            image={tournaments.background}
            alt=""
          />
          <div className="tournaments-content">
            <div className="mb-6">
              <SectionTitle
                title={tournaments.title}
                announceText={tournaments.announceText}
                description={tournaments.description}
              />
              <div className="is-flex is-justify-content-center">
                {/* Tournaments image */}
                <div className="tournaments-image">
                  <SiteImage
                    image={tournaments.image}
                    alt=""
                  />
                  <div className="image-overlay">
                    <SmartLink
                      to={tournaments.primaryButtonLink}
                      className="button is-gradient is-rounded"
                    >
                      {tournaments.primaryButtonText}
                    </SmartLink>
                    <SmartLink
                      to={tournaments.secondaryButtonLink}
                      className="button is-gradient is-outlined is-rounded"
                    >
                      {tournaments.secondaryButtonText}
                    </SmartLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="background-container">
              <div className="challenges-background">
                <SiteImage
                  style={{ width: "100%" }}
                  image={tournaments.challengeBackground}
                  alt=""
                />
              </div>
              <div
                className="columns challenges-content"
                style={{ paddingTop: "10rem", paddingBottom: "10rem" }}
              >
                <div className="column is-5">
                  <SectionTitle
                    title={tournaments.challengeTitle}
                    description={tournaments.challengeDescription}
                    announceText={tournaments.challengeAnnounceText}
                    leftAligned={true}
                  />
                </div>
                <div className="column is-7 is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                  <div className="challenge-image-container">
                    {tournaments.challengeImages.map(image => (
                      <SiteImage
                        className="challenge-image"
                        image={image}
                        alt=""
                      />
                    ))}
                  </div>
                  <SmartLink
                    to={tournaments.challengeButtonLink}
                    className="button is-gradient is-rounded"
                  >
                    {tournaments.challengeButtonText}
                  </SmartLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {gameScreenshots?.images && (
        <section id="game-screenshots" className="section">
          <div className="container is-flex is-flex-direction-column is-align-items-center">
            <SectionTitle
              title={gameScreenshots.title}
              description={gameScreenshots.description}
              announceText={gameScreenshots.announceText}
            />
            <ContinousSlider>
              {gameScreenshots.images.map(image => (
                <SwiperSlide key={image.id} style={{ width: "auto" }}>
                    <SiteImage className="game-screenshot" alt="" image={image} />
                </SwiperSlide>
              ))}
            </ContinousSlider>
            <SmartLink
              to={gameScreenshots.link.link}
              className="button is-gradient is-rounded"
            >
              {gameScreenshots.link.title}
            </SmartLink>
          </div>
        </section>
      )}
      {video && (
        <VideoSection
          announceText={video.announceText}
          title={video.title}
          description={video.description}
          videoLinks={video.videoLinks}
          buttonLink={video.buttonLink}
        />
      )}

      <section id="app-links" className="section">
        <div className="container py-6 is-flex is-flex-direction-column is-align-items-center">
          <SectionTitle
            title={appLinks.title}
            description={appLinks.description}
            announceText={appLinks.announceText}
          />
          <div className="is-flex">
            {appLinks.storeLinks.map(link => (
              <SmartLink
                key={link.id}
                to={link.link}
                className="mr-4"
              >
                <SiteImage image={link.image} alt={link.title} />
              </SmartLink>
            ))}
          </div>
          <SiteImage
            className="mt-6"
            image={appLinks.background}
            alt=""
            style={{ maxWidth: "900px" }}
          />
        </div>
      </section>
      {clubContact && (
        <section id="club-contact" className="section">
          <div
            className="container"
            style={{ paddingTop: "10rem", paddingBottom: "10rem" }}
          >
            <div className="background-image-container">
              <SiteImage
                image={clubContact.image}
                alt=""
                objectFit="contain"
                className="background-image"
              />
            </div>
            <div className="content-container is-flex is-flex-direction-column is-align-items-center">
              <SectionTitle
                title={clubContact.title}
                description={clubContact.description}
                announceText={clubContact.announceText}
              />
              <div>
                <b>{clubContact.linkQuestion}</b>
              </div>
              <SmartLink
                to={clubContact.link.link}
                className="button px-6 my-6 is-rounded is-gradient"
              >
                {clubContact.link.title}
              </SmartLink>
            </div>
          </div>
        </section>
      )}
      {faq && (
        <FaqSection
          announceText={faq.announceText}
          title={faq.title}
          faqItems={faq.faqItem}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  {
    strapiHome {
      seo {
        title
        description
        keywords
        jsonLDSchema
      }
      hero {
        id
        title
        subtitle
        description
        link
        linkTitle
        secondaryLink
        secondaryLinkTitle
        thumbnailLabel
        thumbnailImage {
          ...StrapiImage
        }
        background {
          ...StrapiImage
        }
        heroImage {
          ...StrapiImage
        }
      }
      featureList {
        title
        feature {
          id
          title
          description
          linkTitle
          link
          logo {
            ...StrapiImage
          }
        }
      }
      propositionList {
        title
        proposition {
          id
          title
          description
          logo {
            ...StrapiImage
          }
        }
      }
      tournaments {
        title
        announceText
        description
        challengeTitle
        challengeAnnounceText
        challengeDescription
        challengeButtonText
        challengeButtonLink
        image {
          ...StrapiImage
        }
        background {
          ...StrapiImage
        }
        challengeImages {
          ...StrapiImage
        }
        challengeBackground {
          ...StrapiImage
        }
        primaryButtonText
        primaryButtonLink
        secondaryButtonText
        secondaryButtonLink
      }
      appLinks {
        title
        description
        announceText
        background {
          ...StrapiImage
        }
        storeLinks {
          id
          title
          link
          image {
            ...StrapiImage
          }
        }
      }
      clubContact {
        title
        description
        announceText
        linkQuestion
        link {
          link
          isInternalLink
          title
        }
        image {
          ...StrapiImage
        }
      }
      video {
        announceText
        title
        description
        videoLinks {
          id
          link
        }
        buttonLink {
          title
          link
        }
      }
      gameScreenshots {
        title
        description
        announceText
        link {
          title
          link
        }
        images {
          ...StrapiImage
        }
      }
      faq {
        announceText
        title
        faqItem {
          id
          question
          answer {
            data {
              answer
            }
          }
        }
      }
    }
  }
`
